import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileZipper } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Layout } from '../../components/layout'

const MM24 = () => {
  return (
    <Layout>
      <div className="mt-16">
        <section className="hero">
          <div className="hero-body">
            <div className="container mx-auto">
              <div className="flex justify-center">
                <div className="">
                  <h1 className="text-4xl font-bold text-center">
                    Explicit Granularity and Implicit Scale Correspondence Learning for Point-Supervised Video Moment
                    Localization
                  </h1>
                  <div className="text-xl mt-4 flex justify-center space-x-1">
                    <div>
                      <a href="https://khylon.wang" className="hover:underline">
                        Kun Wang
                      </a>
                      <sup>1</sup>,
                    </div>
                    <div>
                      Hao Liu<sup>1</sup>,
                    </div>
                    <div>
                      Lirong Jie<sup>1</sup>,
                    </div>
                    <div>
                      Zixu Li<sup>1</sup>,
                    </div>
                    <div>
                      <a href="https://faculty.sdu.edu.cn/huyupeng1/zh_CN/index.htm" className="hover:underline">
                        Yupeng Hu
                      </a>
                      <sup>1</sup>,
                    </div>
                    <div>
                      <a href="https://liqiangnie.github.io/" className="hover:underline">
                        Liqiang Nie
                      </a>
                      <sup>2</sup>
                    </div>
                  </div>

                  <div className="text-xl mt-4 flex justify-center space-x-2">
                    <div>
                      <sup>1</sup>Shandong University
                    </div>
                    <div>
                      <sup>2</sup>Harbin Institute of Technology (Shenzhen)
                    </div>
                  </div>

                  <div className="mt-6 text-xl">
                    <div className="flex justify-center space-x-4">
                      <a href="https://doi.org/10.1145/3664647.3680774" className="btn btn-dark">
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <span>Paper</span>
                      </a>
                      <a href="https://github.com/KhylonWong" className="btn btn-dark" onClick={(e) => e.preventDefault()}>
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                        <span>Code</span>
                      </a>
                      <a href="https://drive.google.com/drive/folders/11Prcng5y7y1LHq-OlRO0AVLhQ-nT2UGf?usp=sharing" className="btn btn-dark" >
                        <span className="mr-2">
                          <FontAwesomeIcon icon={faFileZipper} />
                        </span>
                        <span>Model</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h2 className="text-xl font-bold text-center mt-8">The usage instructions and model are currently being organized and will be available soon.</h2>

        <div className="container max-w-4xl mx-auto mt-8">
          <StaticImage src="../../images/papers/mm24/framework.png" alt="Framework" />
        </div>
        <section className="section">
          <div className="mt-12 container mx-auto">
            <div className="flex justify-center">
              <div className="">
                <h2 className="text-3xl font-bold text-center">Abstract</h2>
                <div className="text-xl mt-4" style={{ textAlign: 'justify' }}>
                  <p>
                    Video moment localization (VML) aims to identify the temporal boundary semantically matching the
                    given query. Compared to other paradigms, point-supervised VML balances localization accuracy and
                    annotation cost. However, it is still in its infancy due to explicit granularity alignment and
                    implicit scale perception. To this end, we propose a Semantic Granularity and Scale Correspondence
                    Integration (SG-SCI) framework aimed at leveraging limited single-frame annotation for
                    correspondence learning. It explicitly models semantic relations of different feature granularities
                    and adaptively mines the implicit semantic scale, thereby enhancing feature representations of
                    varying granularities and scales. SG-SCI employs a granularity correspondence alignment module to
                    align semantic information by leveraging latent prior knowledge. Then we develop a scale
                    correspondence learning strategy to identify and address semantic scale differences. Extensive
                    experiments on benchmark datasets have demonstrated the promising performance of our model over
                    several state-of-the-art competitors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section mt-12" id="BibTeX">
          <div className="container mx-auto text-left">
            <h2 className="text-3xl font-bold">BibTeX</h2>
            <div className="mt-4 container overflow-x-auto py-2 px-2" style={{ backgroundColor: '#f5f5f5' }}>
              <pre className="whitespace-pre text-left">
                <code className="block pl-4">
                  {`@inproceedings{wang2024explicit,
  title={Explicit Granularity and Implicit Scale Correspondence Learning for Point-Supervised Video Moment Localization},
  author={Wang, Kun and Liu, Hao and Jie, Lirong and Li, Zixu and Hu, Yupeng and Nie, Liqiang},
  booktitle={Proceedings of the 32nd ACM International Conference on Multimedia},
  pages={9214--9223},
  year={2024}
}`}
                </code>
              </pre>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default MM24
